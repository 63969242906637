import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { AppealEntity } from 'syft-acp-core/store/types'
import { getMyFlexPoints } from '../helpers'

const getStrikeStructure = (hideStrikesAddBans = false) =>
  !hideStrikesAddBans
    ? {
        title: 'Appealed strike information',
        structure: [
          [
            'strike.strike_reason.display_name',
            {
              header: 'Strike Reason',
            },
          ],
          [
            'strike.internal_notes',
            {
              header: 'Strike notes',
            },
          ],
          [
            'strike.activated_at',
            {
              header: 'Strike date',
              type: structureTypes.TYPE_DATE,
            },
          ],
        ],
      }
    : {}

export const dataStructure = (
  isNew = false,
  isClosed = false,
  country: 'US' | 'UK',
  hideStrikesAddBans = false,
) => [
  {
    title: 'Appeal information',
    structure: [
      [
        'created_at',
        {
          header: 'Appeal raised',
          type: structureTypes.TYPE_DATE,
        },
      ],
      [
        'appeal_reason.id',
        {
          header: 'Appeal reason',
          editable: isNew,
          type: structureTypes.TYPE_APPEAL_REASON,
        },
      ],
      [
        'appeal_text',
        {
          header: 'Appeal text',
          editable: isNew,
        },
      ],
      [
        'salesforce_case_url',
        {
          header: 'Salesforce URL',
          editable: isNew,
        },
      ],
      [
        'notes',
        {
          header: 'Notes',
          editable: !isClosed,
        },
      ],
      [
        'outcome',
        {
          header: 'Outcome',
          editable: !isNew && !isClosed,
          type: structureTypes.TYPE_APPEAL_OUTCOME,
        },
      ],
    ],
  },
  {
    title: 'Worker information',
    structure: [
      [
        'worker.id',
        {
          header: 'Worker ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: ({ worker }: Partial<AppealEntity>) => worker && `/entity/workers/view/${worker.id}`,
          },
        },
      ],
      [
        'worker.full_name',
        {
          header: 'Worker name',
        },
      ],
      [
        'worker.active_strikes_count',
        {
          header: 'Current active strike count',
        },
      ],
      [
        'worker.total_appeals_count',
        {
          header: 'Total appeals',
        },
      ],
      [
        'worker.completed_shifts_count',
        {
          header: 'Completed shifts',
        },
      ],
      hideStrikesAddBans && [
        'worker.id',
        {
          header: 'My flex points',
          calc: ({ worker }: Partial<AppealEntity>) => {
            return getMyFlexPoints({ country, workerId: worker?.id })
          },
        },
      ],
    ].filter(Boolean),
  },
  getStrikeStructure(hideStrikesAddBans),
]
