import * as React from 'react'
import { get, isEqual } from 'lodash-es'

import { ButtonLink } from '@indeed/ifl-components'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import { showConfirmModal, showModal } from 'syft-acp-core/store/modals/actions'
import { store } from 'syft-acp-core/store'
import { A_VERY_LARGE_NUMBER } from '../api/call'

import './helpers.scss'
import DownloadCSVModalContent from '../components/Modal/DownloadCSVModal/DownloadCSVModalContent'
import { FormControl } from 'react-bootstrap'

export const actionDownloadCSV = (view?: string) => (resultData: Record<string, any>) => {
  const total = get(resultData, 'pagination.total', null)
  const query = resultData.query
  const hasFilters = !isEqual(resultData.query, {})
  const totalPages = Math.ceil(total / A_VERY_LARGE_NUMBER)
  let pageToDownload = 1
  let itemsInFile = pageToDownload < totalPages ? A_VERY_LARGE_NUMBER : total % A_VERY_LARGE_NUMBER

  const handlePageChange = (e: React.FormEvent<FormControl>) => {
    const evt = e.target as HTMLInputElement
    pageToDownload = Number(evt.value)
    itemsInFile = pageToDownload < totalPages ? A_VERY_LARGE_NUMBER : total % A_VERY_LARGE_NUMBER

    store.dispatch(
      showConfirmModal({
        modalConfirm: totalPages === 1 ? 'Download' : `Download page ${pageToDownload}`,
        question: formContent(itemsInFile),
        onConfirm: () => store.dispatch(workersActions.fetchWorkersCSV(query, view)),
      }),
    )
  }

  const formContent = (_itemsInFile: number) => (
    <div>
      <p>Download a {view === 'paycom' ? 'Paycom Users' : ''} CSV file with these search results?</p>
      <DownloadCSVModalContent
        hasFilters={hasFilters}
        query={query}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        pageToDownload={pageToDownload}
        total={total}
        itemsInFile={_itemsInFile}
      />
    </div>
  )

  store.dispatch(
    showConfirmModal({
      modalConfirm: totalPages === 1 ? 'Download' : `Download page ${pageToDownload}`,
      question: formContent(itemsInFile),
      onConfirm: () => store.dispatch(workersActions.fetchWorkersCSV(query, view)),
    }),
  )
}

export const actionUploadCSV = () => () => {
  store.dispatch(showModal('transferredWorkerCSVModal'))
}

export const getMyFlexPoints = ({ country, workerId }: { country: 'US' | 'UK'; workerId?: number }) => {
  const link = `https://flex-redash.indeed.tech/queries/24494?p_Worker%20ID=$${workerId}${country}`
  return (
    <ButtonLink sx={{ marginLeft: '-12px' }} href={link} target="_blank" rel="noreferrer">
      Link to MyPoints
    </ButtonLink>
  )
}
