import React from 'react'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { connect } from 'react-redux'

import * as conductReviewsActions from 'syft-acp-core/store/conductReviews/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import { trackingEvents } from '../ConductReviewDetail.tracking'
import { ConductReviewDetailControlsProps as Props } from './ConductReviewDetailControls.types'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

export const storeConnector = connect(
  ({ conductReviews: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(conductReviewsActions, dispatch),
  }),
)

const defaultData = {
  notes: '',
  outcome: null,
  ban_reason_id: null,
}

const getFinalOutcome = (outcome?: string) => {
  if (!outcome) return undefined
  if (outcome === 'no_ban') return 'no_ban'
  return 'ban'
}

const ConductReviewDetailControls = ({
  actions,
  data = defaultData,
  id = 0,
  isSavingData,
  isLoadingData,
  isPristine,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const { notes, outcome } = data as any
  const isClosed = isPristine && !!outcome
  const banReviews = useFlexFlagIsOn('pte_32272_hide_strikes_add_bans')

  const saveAction = () => {
    triggerEvent(trackingEvents.SAVE.CLICKED, { conduct_review_id: id })
    const finalOutcome = getFinalOutcome(outcome)
    if (id)
      actions.updateConductReview(id, {
        notes: notes ?? '',
        outcome: finalOutcome,
        ban_reason_id: finalOutcome === 'ban' ? outcome : undefined,
      })
  }

  const onClickBack = () => {
    triggerEvent(trackingEvents.BACK.CLICKED, { conduct_review_id: id })
  }

  return (
    <RecordControls>
      <div className="spacer">
        <ButtonGroup>
          {banReviews ? (
            <Link to={`/conduct/ban-reviews/list`}>
              <Button onClick={onClickBack}>Back to ban reviews</Button>
            </Link>
          ) : (
            <Link to={`/conduct/conduct-reviews/list`}>
              <Button onClick={onClickBack}>Back to conduct reviews</Button>
            </Link>
          )}
        </ButtonGroup>
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        <ButtonGroup>
          {!isClosed && (
            <Button kind="success" onClick={saveAction} disabled={isSavingData || isPristine}>
              Save
            </Button>
          )}
        </ButtonGroup>
      </div>
    </RecordControls>
  )
}

export default storeConnector(ConductReviewDetailControls)
