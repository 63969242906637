// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { Button, ButtonLink, ButtonGroup } from 'syft-acp-atoms/Button'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { store } from 'syft-acp-core/store'
import * as employersActions from 'syft-acp-core/store/employers/actions'
import * as employerTokenActions from 'syft-acp-core/actions/employer-tokens'
import EmployerDownloadInvoicesModal from 'syft-acp-core/components/Modal/EmployerDownloadInvoicesModal'
import { getTokenOpenLogin } from './helpers'
import { loginAsEmployerBlacklistedIds } from './constants'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

export class EmployerDetailControlsLeft extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    token: PropTypes.object,
    actions: PropTypes.object,
    userData: PropTypes.object,
    id: PropTypes.number,
  }

  static defaultProps = {
    id: null,
    token: null,
    actions: null,
    data: null,
    userData: null,
  }

  /** Checks whether our token to login as employer is still valid. If not, request a new one.
   * @param {Object} options
   * @param {boolean} options.flexAgent - redirects to flex-agent if true
   **/
  checkToken = (options = {}) => {
    const { actions, data, token, userData, id } = this.props
    getTokenOpenLogin({
      actions,
      data,
      token,
      userData,
      employerId: id,
      flexAgent: options.flexAgent,
    })
  }

  invoiceAction = () => {
    store.dispatch(showModal('employerDownloadInvoicesModal'))
  }

  render() {
    const { data, isFlexAgentOn } = this.props
    const employerID = data?.id
    const isVerified = data && !!data.approved_at
    const hasOneLineInvoicing = data && data.one_line_invoicing
    const isLoginAsEmployerBlacklisted = loginAsEmployerBlacklistedIds.includes(employerID)
    return (
      <span>
        <ReactTooltip effect="solid" place="bottom" />
        <EmployerDownloadInvoicesModal employerID={data && data.id} />{' '}
        <ButtonGroup>
          <ButtonLink to={`/shifts?employer_id=${employerID}`} disabled={!employerID}>
            View shifts
          </ButtonLink>

          {!isLoginAsEmployerBlacklisted && (
            <Button onClick={this.checkToken} external target="_blank">
              <span
                {...(!isVerified ? { 'data-tip': 'Employer must be verified before you can login' } : {})}
              >
                Login as employer
              </span>
            </Button>
          )}
        </ButtonGroup>{' '}
        <ButtonGroup>
          <Button onClick={this.invoiceAction} disabled={!hasOneLineInvoicing}>
            <span
              {...(!hasOneLineInvoicing
                ? { 'data-tip': 'Can only download invoices if one line invoicing is active' }
                : {})}
            >
              Download invoices
            </span>
          </Button>
        </ButtonGroup>{' '}
        {!isLoginAsEmployerBlacklisted && isFlexAgentOn && (
          <ButtonGroup>
            <Button onClick={() => this.checkToken({ flexAgent: true })} external target="_blank">
              <span
                {...(!isVerified ? { 'data-tip': 'Employer must be verified before you can login' } : {})}
              >
                Flex Agent
              </span>
            </Button>
          </ButtonGroup>
        )}
      </span>
    )
  }
}

const EmployerDetailControlsLeftWrapper = props => {
  const isFlexAgentOn = useFlexFlagIsOn('wfm_flex_assistant')
  return <EmployerDetailControlsLeft {...props} isFlexAgentOn={isFlexAgentOn} />
}

export const EmployerDetailControlsLeftConnected = connect(
  (state, ownProps) => ({
    data: state.employers.entityDetail[ownProps.employerID],
    token: state.employerToken?.employers[ownProps.employerID],
    userData: state.auth?.userData,
    isPristine: true,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...employerTokenActions, ...employersActions }, dispatch),
  }),
)(EmployerDetailControlsLeftWrapper)
