import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'
import { get } from 'lodash-es'
import ReactTooltip from 'react-tooltip'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { showConfirmModal, showModal } from 'syft-acp-core/store/modals/actions'
import * as listingsActions from 'syft-acp-core/store/listings/actions'
import * as employerActions from 'syft-acp-core/store/employers/actions'
import { store } from 'syft-acp-core/store'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import ListingEmailCSVModal from 'syft-acp-core/components/Modal/ListingEmailCSVModal'
import { getTokenOpenLogin } from 'syft-acp-core/entities/EmployerDetail/helpers'
import * as employerTokenActions from 'syft-acp-core/actions/employer-tokens'
import { trackingEvents as trackingEventsWorkersInfo } from 'syft-acp-core/entities/ListingRatings/tracking'
import { trackingEvents as trackingEventsListingInfo } from './tracking'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

export const getListingOrWorkerInfoEvent = (isListingWorkerInfo, workerInfoEvent, listingInfoEvent) =>
  isListingWorkerInfo ? workerInfoEvent : listingInfoEvent

const ListingDetailControls = ({
  data,
  isSavingData,
  isLoadingData,
  isPristine,
  hasControlButtons,
  actions,
  userData,
  modifiedData,
  token,
  shiftID,
}) => {
  const triggerEvent = useTrackingTrigger()
  const isListingWorkerInfo = window.location.pathname.includes('/listings/ratings/')
  const employerId = get(data, 'employer.id')
  const employerLoginFailedReason = () => {
    const tokenFailed = null
    const tokenFailedReason = null
    if (!tokenFailed) return null
    return `Unable to get employer login token${tokenFailedReason ? `: ${tokenFailedReason.trim()}` : ''}`
  }

  /** Retrieve a login token for the employer.
   * @param {Object} tokenData
   * @param {number} tokenData.id
   * @param {boolean} tokenData.approved_at
   * @param {number} tokenData.listingId - optional
   * @param {number} tokenData.shiftID - optional
   * @param {Object} options
   * @param {boolean} options.flexAgent - redirects to flex-agent if true
   **/
  const retrieveLoginToken = (tokenData, options = {}) => {
    getTokenOpenLogin({
      actions,
      data: tokenData,
      token,
      userData,
      employerId,
      flexAgent: options.flexAgent,
    })
  }

  /** Retrieve a login token for the employer that leads to this listing. */
  const retrieveListingLoginToken = () => {
    const event = getListingOrWorkerInfoEvent(
      isListingWorkerInfo,
      trackingEventsWorkersInfo.LISTING_WORKER_INFO.VIEW_LISTING_ON_PORTAL.CLICKED,
      trackingEventsListingInfo.LISTING_INFO.VIEW_LISTING_ON_PORTAL.CLICKED,
    )
    triggerEvent(event, {
      employerId,
    })
    const firstShiftId = data?.jobs?.[0]?.shifts?.[0]?.id
    const tokenData = {
      id: employerId,
      approved_at: true, // Employer with listing is approved
      listingId: data?.id,
      shiftID: shiftID || firstShiftId,
    }
    retrieveLoginToken(tokenData)
  }

  /** Retrieve a login token for the employer that leads to the flex agent. */
  const retrieveFlexAgentLoginToken = () => {
    const event = getListingOrWorkerInfoEvent(
      isListingWorkerInfo,
      trackingEventsWorkersInfo.LISTING_WORKER_INFO.FLEX_ASSISTANT.CLICKED,
      trackingEventsListingInfo.LISTING_INFO.FLEX_ASSISTANT.CLICKED,
    )
    triggerEvent(event, {
      employerId,
    })

    const tokenData = {
      id: employerId,
      approved_at: true, // Employer with listing is approved
    }
    retrieveLoginToken(tokenData, { flexAgent: true })
  }

  const saveAction = () => {
    triggerEvent(trackingEventsListingInfo.LISTING_INFO.LISTING.SAVED, {
      listingId: data.id,
    })
    return actions.saveListing(data.id, { ...modifiedData, venue_id: data.venue_id })
  }

  const backToShiftsAction = () => {
    const event = getListingOrWorkerInfoEvent(
      isListingWorkerInfo,
      trackingEventsWorkersInfo.LISTING_WORKER_INFO.BACK_TO_SHIFTS.CLICKED,
      trackingEventsListingInfo.LISTING_INFO.BACK_TO_SHIFTS.CLICKED,
    )
    triggerEvent(event, {
      employerId,
    })
    return history.push(`/shifts`)
  }

  const timesheetAction = () => {
    const event = getListingOrWorkerInfoEvent(
      isListingWorkerInfo,
      trackingEventsWorkersInfo.LISTING_WORKER_INFO.EMAIL_TIMESHEET.CLICKED,
      trackingEventsListingInfo.LISTING_INFO.EMAIL_TIMESHEET.CLICKED,
    )
    triggerEvent(event)
    return store.dispatch(showModal('listingEmailCSVModal'))
  }

  const toEmployer = () => {
    const event = isListingWorkerInfo
      ? trackingEventsWorkersInfo.LISTING_WORKER_INFO.VIEW_EMPLOYER.CLICKED
      : trackingEventsListingInfo.LISTING_INFO.VIEW_EMPLOYER.CLICKED
    triggerEvent(event, {
      employerId,
    })
    if (employerId) {
      return history.push(`/entity/employers/view/${employerId}`)
    }
    return null
  }

  const deleteAction = () => {
    store.dispatch(
      showConfirmModal({
        question: 'Are you sure you want to delete ALL jobs and ALL shifts within this listing?',
        onConfirm: () => {
          triggerEvent(trackingEventsListingInfo.LISTING_INFO.LISTING.DELETED, {
            listingId: data.id,
          })
          return actions.deleteListing(data.id)
        },
        modalConfirm: 'Yes',
      }),
    )
  }

  const handleResendApproval = () => {
    const event = getListingOrWorkerInfoEvent(
      isListingWorkerInfo,
      trackingEventsWorkersInfo.LISTING_WORKER_INFO.RESEND_APPROVAL.CLICKED,
      trackingEventsListingInfo.LISTING_INFO.RESEND_APPROVAL.CLICKED,
    )
    triggerEvent(event, {
      employerId,
    })
    actions.resendApproval({ employerId, listingId: get(data, 'id') })
  }

  const isFlexAgentOn = useFlexFlagIsOn('wfm_flex_assistant')

  return (
    <RecordControls>
      <ReactTooltip effect="solid" id="listing-details-tooltip" place="bottom" />
      <ListingEmailCSVModal listingID={data.id} />
      <div className="spacer">
        <ButtonGroup>
          <Button onClick={backToShiftsAction}>Back to shifts</Button>
        </ButtonGroup>{' '}
        <ButtonGroup>
          <Button onClick={toEmployer}>View employer</Button>
          <Button onClick={timesheetAction}>Email timesheet</Button>
        </ButtonGroup>{' '}
        <ButtonGroup data-tip={employerLoginFailedReason} data-for="listing-details-tooltip">
          <Button onClick={retrieveListingLoginToken} external target="_blank">
            View listing on Portal
          </Button>
        </ButtonGroup>{' '}
        <ButtonGroup>
          <Button onClick={handleResendApproval}>Resend for Approval</Button>
        </ButtonGroup>{' '}
        {isFlexAgentOn && (
          <ButtonGroup>
            <Button onClick={retrieveFlexAgentLoginToken} external target="_blank">
              Flex Agent
            </Button>
          </ButtonGroup>
        )}
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        {hasControlButtons && (
          <>
            <ButtonGroup>
              <Button kind="success" onClick={saveAction} disabled={isSavingData || isPristine}>
                Save
              </Button>
            </ButtonGroup>{' '}
            <ButtonGroup>
              <Button kind="danger" onClick={deleteAction}>
                Delete
              </Button>
            </ButtonGroup>
          </>
        )}
      </div>
    </RecordControls>
  )
}

ListingDetailControls.propTypes = {
  data: PropTypes.object.isRequired,
  modifiedData: PropTypes.object.isRequired,
  isPristine: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  hasControlButtons: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  token: PropTypes.object,
  isSavingData: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isLoadingData: PropTypes.bool,
  shiftID: PropTypes.string,
}

ListingDetailControls.defaultProps = {
  isLoadingData: false,
  hasControlButtons: false,
}

export default connect(
  (state, props) => ({
    isSavingData: get(state, 'listings.isSavingData'),
    isLoadingData: get(state, 'listings.isLoadingData'),
    userId: get(state, 'auth.userData.id'),
    token: state.employerToken?.employers[props?.data?.employer?.id],
    userData: state.auth?.userData,
  }),
  dispatch => ({
    actions: bindActionCreators(
      { ...listingsActions, ...employerActions, ...employerTokenActions },
      dispatch,
    ),
  }),
)(ListingDetailControls)
